import * as React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import { 
  Box,
  Heading,
  Flex,
  Text,
  Container,
  Center,
  Button,
  Link
} from '@chakra-ui/react'
import Seo from '../components/Seo'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { PrismicRichText } from "@prismicio/react"


const IndexPage = ({ data, location }) => {
  const document = data.prismicHomepage.data;
  console.log(document.home_slider)

const treeBgImg = getImage(data.treeImage)
const treeBgImage = convertToBgImage(treeBgImg)

const graphPaperImg = getImage(data.graphPaperImage)
const graphPaperImage = convertToBgImage(graphPaperImg)

  return (
    <Layout
      pageLocation={location}
      slides={document.home_slider}
      // heroImage={document.home_hero.localFile}
      // heroImageAlt={document.home_hero.alt}
    >
      <Seo title={document.seo_title.text} description={document.meta_description.text} />
      <Box as="section">
      <BackgroundImage
        id="tree-background"
          {...treeBgImage}
          preserveStackingContext>
        
        <Flex h="100%" w="100%" flexDirection="column" justifyContent="flex-start" pt="12" pb="64">
          <Container maxW="container.xl">
            <Flex w={{base: '100%', lg: '75%'}} flexDirection="row" justifyContent="center" mx="auto">
              <Box w="60%">
                <GatsbyImage image={getImage(document.pine_tree_block_main_image.localFile)} alt={document.pine_tree_block_main_image.alt} />
              </Box>
              {/* <Box w="35%" alignSelf="flex-end" ml="-24" mb={{lg: "-24"}} pt={{base: '16', lg: 'none'}} display={{base: 'none', lg: 'block'}}>
                <StaticImage src="../images/aspen-leaves.png" alt="Aspen Leaves" placeholder="traced_svg" />
              </Box> */}
            </Flex>
          </Container>
          

          <Container maxWidth="container.lg" display={{base: 'none', lg: 'block'}}>
              <Heading as="h2" mt="12" fontFamily="Roboto" textAlign="center">
                {document.pine_tree_block_headline}
              </Heading>
              <Flex w="100%">
                <Box w={{base: '100%', lg: '100%'}}>
                  <Box mt="8" px="8">
                    <PrismicRichText field={document.pine_tree_block_paragraph_1.richText} />
                  </Box>
                  <Box mt="8" px="8">
                    <PrismicRichText field={document.pine_tree_block_paragraph_2.richText} />
                  </Box>
                  <Center my="8">
                    <Button as={GatsbyLink} to={document.button_link.url}  textTransform="uppercase" borderColor="olive-green.500" color="olive-green.500" borderRadius="none" fontFamily="Roboto">
                      {document.button_link_label}
                    </Button>
                  </Center>
                </Box>
              </Flex>
            </Container>

            <Container maxWidth="container.lg" display={{base: 'block', lg: 'none'}}>
              <Heading as="h2" mt="8" fontFamily="Roboto" textAlign="center">
              {document.pine_tree_block_headline}
              </Heading>
              
                <Box w={{base: '100%', lg: '50%'}}>
                  <Box mt="8" px="8">
                    <PrismicRichText field={document.pine_tree_block_paragraph_1.richText} />
                  </Box>
                  <Box mt="8" px="8" pt="8">
                    <PrismicRichText field={document.pine_tree_block_paragraph_2.richText} />
                  </Box>
                  <Center my="8">
                    <Button as={GatsbyLink} to={document.button_link.url} variant="outline" textTransform="uppercase" borderColor="olive-green.500" color="olive-green.500" borderRadius="none" fontFamily="Roboto">
                      {document.button_link_label}
                    </Button>
                  </Center>
                </Box>
              
            </Container>

        </Flex>
        </BackgroundImage>
      </Box>

      <Box as="section">
        <BackgroundImage
          id="graph-paper-background"
          {...graphPaperImage}
          preserveStackingContext>
        {/* <Box top="0" left="0">
          <StaticImage src="../images/graph-paper.jpg" alt="Graph paper background" style={{width: '100%', height: '100%'}}  />
        </Box> */}
        <Flex w="100%" height="100%" py="48">
          
            <Flex w={{base: "100%", md: "60%"}} flexDirection="column" h="100%" justifyContent="center" pl={{base: "0", md: "5%", lg: "10%"}} my="auto">
              <Heading as="h2" fontFamily="Roboto" mb="4" textAlign={{base: 'center', md: 'left'}}>
                {document.drawing_block_headline}
              </Heading>
              <Box px="12">
                <PrismicRichText field={document.drawing_block_paragraph.richText} />
              </Box>
              <Center my="8">
                    <Button as={GatsbyLink} to={document.drawing_block_link.url} variant="outline" textTransform="uppercase" borderColor="olive-green.500" color="olive-green.500" borderRadius="none" fontFamily="Roboto">
                      {document.drawing_block_link_label}
                    </Button>
                  </Center>
            </Flex>
          
          <Flex w={{ base: "60%", md: "40%"}} alignItems="flex-end" position="absolute" bottom="0" right="0" zIndex="-1" style={{mixBlendMode: 'darken'}}>
            <StaticImage src="../images/drawing.png" alt="Architectural Drawing" placeholder="blurred" />
          </Flex>
        </Flex>
        </BackgroundImage>
      </Box>

      <Box as="section" mb="16">
        <Container maxW="container.xl">
          <Flex w='100%' px={{base: "0", md: "12"}} py="24" flexWrap={{base: 'wrap', md: 'nowrap'}}>
            <Box pl={{base: "0", md: "8"}} pr={{base: "0", md: "4"}} >
              <Link as={GatsbyLink} to="/projects" _hover={{textDecoration: 'none'}} state={{project_type: 'Residential'}}>
                <Box mb="2">
                  <GatsbyImage image={getImage(document.work_block_image_1.localFile)} alt={document.work_block_image_1.alt} imgStyle={{width: '100%'}}/>
                </Box>
                <Text textTransform='uppercase' fontSize="1.5rem">
                  {document.work_block_image_1_label}
                </Text>
              </Link>
            </Box>
            <Box px={{base: "0", md: "4"}} >
              <Link as={GatsbyLink} to="/projects" _hover={{textDecoration: 'none'} }state={{project_type: 'Workplace' }}>
                <Box mb="2">
                  <GatsbyImage image={getImage(document.work_block_image_2.localFile)} alt={document.work_block_image_2.alt} imgStyle={{width: '100%'}}/>
                </Box>
                <Text textTransform='uppercase' fontSize="1.5rem">
                {document.work_block_image_2_label}
                </Text>
              </Link>
            </Box>
            <Box pl={{base: "0", md: "4"}} pr={{base: "0", md: "8"}}>
              <Link as={GatsbyLink} to="/projects" _hover={{textDecoration: 'none'}} state={{project_type: 'Education'}}>
                <Box mb="2">
                  <GatsbyImage image={getImage(document.work_block_image_3.localFile)} alt={document.work_block_image_3.alt} imgStyle={{width: '100%'}}/>
                </Box>
                <Text textTransform='uppercase' fontSize="1.5rem">
                {document.work_block_image_3_label}
                </Text>
              </Link>
            </Box>
          </Flex>
        </Container>
        <Container maxWidth="container.xl">
            <Flex w="100%" flexDirection="column" h="100%" justifyContent="center" pl={{base: "0", md: "8"}}>
                <Box>
                  <Heading as="h2" fontFamily="Roboto" mb="4" textAlign={{base: 'center'}}>
                    {document.work_block_headline}
                  </Heading>
                  <Flex w='100%' flexWrap="wrap">
                    <Box w={{base: '100%'}}>
                      <Box px="12">
                        <PrismicRichText field={document.work_block_paragraph_1.richText} />
                      </Box>
                      <Box px="12">
                        <PrismicRichText field={document.work_block_paragraph_2.richText} />
                      </Box>
                      <Center my="8">
                    <Button as={GatsbyLink} to={document.work_block_link.url} variant="outline" textTransform="uppercase" borderColor="olive-green.500" color="olive-green.500" borderRadius="none" fontFamily="Roboto">
                      {document.work_block_link_label}
                    </Button>
                  </Center>
                    </Box>
                  </Flex>
                </Box>
              </Flex>                
        </Container>
      </Box> 
    </Layout>
  )
}

export const query = graphql`
{
  prismicHomepage {
    data {
      home_hero {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
        alt
      }
      drawing_block_headline
      button_link_label
      button_link {
        url
        target
        uid
      }
      drawing_block_paragraph {
        richText
      }
      home_hero_headline {
        richText
      }
      pine_tree_block_headline
      pine_tree_block_paragraph_2 {
        richText
      }
      pine_tree_block_paragraph_1 {
        richText
      }
      work_block_headline
      work_block_image_1_label
      work_block_image_2_label
      work_block_image_3_label
      work_block_link_label
      work_block_paragraph_2 {
        richText
      }
      work_block_paragraph_1 {
        richText
      }
      work_block_link {
        uid
        url
      }
      work_block_image_3 {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
        }
      }
      work_block_image_2 {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
      }
      work_block_image_1 {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
      }
      pine_tree_block_main_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      drawing_block_link_label
      drawing_block_link {
        url
        target
      }
      seo_title {
        text
      }
      meta_description {
        text
      }
      home_slider {
        slide_title {
          text
        }
        slide_subtitle {
          text
        }
        completed_at_hacker_architects_
        slide_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5, layout: FULL_WIDTH, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
  treeImage: file(relativePath: {eq: "trees.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG)
    }
  }
  graphPaperImage: file(relativePath: {eq: "graph-paper.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG)
    }
  }
}
`

export default IndexPage
